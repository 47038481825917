import {useMatches} from '@remix-run/react';
import cn from 'classnames';

import type {HeaderProps} from '@/components/brochureV2/section/Header/types';
import Header from '@/components/brochureV2/section/Header/Header';
import {HeaderBackgroundColorScheme} from '@/enums';
import {isRetailHardwareAndPaymentsAvailable} from '@/pages/shopify.com/($locale)/pos/posLocaleRules';
import Button from '@/components/base/elements/Button/Button';
import {useTranslations} from '@/hooks/useTranslations';
import {useRetailUnifiedFunnel} from '@/hooks/experiments/useRetailUnifiedFunnel';
import Anchor from '@/components/shared/Anchor/Anchor';
import useGlobalNav from '@/hooks/navigation/global';

type NavKeyMapType = {
  [key: string]: string;
};

// Map pathname to nav key
const navKeyMap: NavKeyMapType = {
  'android-pos': 'posAndroid',
  features: 'allFeatures',
  'retail-pos': 'retail',
  hardware: 'hardware',
  'ipad-pos': 'posIpad',
  'multi-store-pos': 'posMultiStore',
  omnichannel: 'omnichannel',
  payments: 'payments',
  'pop-up-sales': 'overview',
  'pos-app': 'posApp',
  'pos-inventory-system': 'posInventorySystem',
  'pos-customization': 'posCustomization',
  'pos-software': 'posSoftware',
  'pos-system-small-business': 'posSystemSmallBusiness',
  pricing: 'posPricing',
  'request-info': 'retail',
  'staff-management': 'staffManagement',
};

export default function PosHeader(props: HeaderProps, pageTitle: string) {
  const matches = useMatches();
  // Get the last pathname in the matches array (the deepest nested route)
  const {pathname} = matches[matches.length - 1];
  const formattedPathName = pathname.split('/').pop();
  const pageTitleCombined =
    pageTitle ?? navKeyMap[formattedPathName ?? ''] ?? 'pos';
  const {background, ...restProps} = props;
  const backgroundColorScheme =
    props?.background || HeaderBackgroundColorScheme.TransparentLight;

  const {t: translate} = useTranslations();
  const {isRetailUnifiedFunnelExperiment} = useRetailUnifiedFunnel();
  const {loginUrl, loginText} = useGlobalNav();
  const darkBackground =
    backgroundColorScheme === HeaderBackgroundColorScheme.Black;

  const customDesktopLinks = isRetailUnifiedFunnelExperiment ? (
    <>
      <li className="mr-8 text-base">
        <Anchor
          className={cn('whitespace-nowrap hover:underline', {
            'text-white': darkBackground,
            'text-black': !darkBackground,
          })}
          href={loginUrl}
          componentName="login"
        >
          {loginText}
        </Anchor>
      </li>
      <li className="leading-[0]">
        <Button
          href="#contact-sales"
          mode={darkBackground ? 'dark' : 'light'}
          size="small"
          className="py-2 px-5 text-base font-bold tracking-[-0.01em] whitespace-nowrap"
        >
          {translate('pos:components.contactSalesCta')}
        </Button>
      </li>
    </>
  ) : undefined;

  const customMobileLinks = isRetailUnifiedFunnelExperiment ? (
    <li className="mr-8 text-base">
      <Anchor
        className={cn('whitespace-nowrap hover:underline', {
          'text-white': darkBackground,
          'text-black': !darkBackground,
        })}
        href="#contact-sales"
        componentName="login"
      >
        {translate('pos:components.contactSalesCta')}
      </Anchor>
    </li>
  ) : undefined;

  const customMobileMenuCtaLinks = isRetailUnifiedFunnelExperiment ? (
    <>
      <li className="py-1 sm:grow sm:pt-2">
        <Button
          href={loginUrl}
          intent="secondary"
          className="w-full py-2 px-5 text-base font-bold"
          mode={darkBackground ? 'dark' : 'light'}
          componentName="login"
        >
          {loginText}
        </Button>
      </li>
      <li className="pt-1 sm:grow sm:pt-2">
        <Button
          href="#contact-sales"
          className="w-full py-2 px-5 text-base font-bold"
          mode={darkBackground ? 'dark' : 'light'}
        >
          {translate('pos:components.contactSalesCta')}
        </Button>
      </li>
    </>
  ) : undefined;

  return (
    <Header
      {...restProps}
      CustomDesktopCtaLinklist={customDesktopLinks}
      CustomMobileCtaLinklist={customMobileLinks}
      CustomMobileMenuCtaLinklist={customMobileMenuCtaLinks}
      background={backgroundColorScheme}
      secondaryNavFn={({t, localizePath, site}) => {
        const hasHardwareAndPaymentsLink = isRetailHardwareAndPaymentsAvailable(
          site.locale,
        );
        const links = [
          {
            text: t('nav:retail'),
            url: localizePath('/pos/retail-pos'),
            name: 'pos-retail-pos',
          },
          {
            text: t('nav:features'),
            name: 'pos-features',
            links: [
              {
                text: t('nav:hardware'),
                url: localizePath('/pos/hardware'),
                name: 'pos-hardware',
              },
              {
                text: t('nav:omnichannel'),
                url: localizePath('/pos/omnichannel'),
                name: 'pos-omnichannel',
              },
              {
                text: t('nav:payments'),
                url: localizePath('/pos/payments'),
                name: 'pos-payments',
              },
              {
                text: t('nav:staffManagement'),
                url: localizePath('/pos/staff-management'),
                name: 'pos-staff-management',
              },
              {
                text: t('nav:allFeatures'),
                url: localizePath('/pos/features'),
                name: 'pos-features',
              },
            ].filter(
              (link) =>
                hasHardwareAndPaymentsLink ||
                !['pos-hardware', 'pos-payments'].includes(link.name),
            ),
          },
          {
            text: t('nav:posPricing'),
            url: localizePath('/pos/pricing'),
            name: 'pos-pricing',
          },
        ];

        const caAndUsOnlyLinks = [
          {
            text: t('nav:businessTypes'),
            name: 'pos-businessTypes',
            links: [
              {
                text: t('nav:retail'),
                url: localizePath('/pos/retail-pos'),
                name: 'pos-retail-pos',
              },
              {
                text: t('nav:posSystemSmallBusiness'),
                url: localizePath('/pos/pos-system-small-business'),
                name: 'pos-system-small-business',
              },
              {
                text: t('nav:posMultiStore'),
                url: localizePath('/pos/multi-store-pos'),
                name: 'pos-multi-store',
              },
            ],
          },
          {
            text: t('nav:features'),
            name: 'pos-features',
            links: [
              {
                text: t('nav:hardware'),
                url: localizePath('/pos/hardware'),
                name: 'pos-hardware',
              },
              {
                text: t('nav:posSoftware'),
                name: 'pos-software',
                links: [
                  {
                    text: t('nav:posSoftware'),
                    url: localizePath('/pos/pos-software'),
                    name: 'pos-software',
                  },
                  {
                    text: t('nav:posApp'),
                    url: localizePath('/pos/pos-app'),
                    name: 'pos-app',
                  },
                  {
                    text: t('nav:posIpad'),
                    url: localizePath('/pos/ipad-pos'),
                    name: 'pos-ipad',
                  },
                  {
                    text: t('nav:posAndroid'),
                    url: localizePath('/pos/android-pos'),
                    name: 'pos-android',
                  },
                ],
              },
              {
                text: t('nav:omnichannel'),
                url: localizePath('/pos/omnichannel'),
                name: 'pos-omnichannel',
              },
              {
                text: t('nav:payments'),
                url: localizePath('/pos/payments'),
                name: 'pos-payments',
              },
              {
                text: t('nav:staffManagement'),
                url: localizePath('/pos/staff-management'),
                name: 'pos-staff-management',
              },
              {
                text: t('nav:posInventorySystem'),
                url: localizePath('/pos/pos-inventory-system'),
                name: 'pos-inventory-system',
              },
              {
                text: t('nav:customization'),
                url: localizePath('/pos/customization'),
                name: 'pos-customization',
              },
              {
                text: t('nav:allFeatures'),
                url: localizePath('/pos/features'),
                name: 'pos-features',
              },
            ].filter(
              (link) =>
                hasHardwareAndPaymentsLink ||
                !['pos-hardware', 'pos-payments'].includes(link.name),
            ),
          },
          {
            text: t('nav:posPricing'),
            url: localizePath('/pos/pricing'),
            name: 'pos-pricing',
          },
        ];

        const withinUsOrCa =
          (site.countryCode === 'US' || site.countryCode === 'CA') &&
          site.languageCode === 'en';

        return {
          sectionTitle: t('nav:pointOfSale'),
          sectionOverviewUrl: localizePath('/pos'),
          pageTitle: t(`nav:${pageTitleCombined}`),
          links: withinUsOrCa ? caAndUsOnlyLinks : links,
        };
      }}
    />
  );
}
